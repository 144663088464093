<template>
  <v-container>
    <div class="d-flex justify-end">
      <v-btn color="primary" :to="{ name: 'Test.Create' }">New Test</v-btn>
    </div>
    <v-row class="mb-2">
      <v-col cols="12" md="6">
        <h1>Test</h1>
      </v-col>
      <v-col cols="12" md="6">
        <v-form @submit.prevent="options.search = searchForm">
          <v-text-field
            v-model="searchForm"
            @click:append="clearSearch"
            :append-icon="searchForm ? 'mdi-close' : ''"
            prepend-icon="mdi-magnify"
            :label="$vuetify.lang.t('$vuetify.search')"
            single-line
            hide-details
          >
          </v-text-field>
        </v-form>
      </v-col>
    </v-row>
    <data-table
      :headers="headers"
      :items="tests"
      :options.sync="options"
      :server-items-length="totalData"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          first-icon="mdi-chevron-double-left"
          last-icon="mdi-chevron-double-right"
          prev-icon="mdi-chevron-left"
          next-icon="mdi-chevron-right"
          :items-per-page-options="[25, 50, 100, -1]"
          :options="options"
          :pagination="pagination"
          :show-first-last-page="true"
          :show-current-page="true"
        />
      </template>
      <template
        v-slot:[`item.calculationSystem`]="{ item: { calculationSystem } }"
      >
        <span class="capitalize">{{
          calculationSystem | formatCalculationSystem
        }}</span>
      </template>
      <template v-slot:[`item.testStart`]="{ item: { testStart } }">
        {{ testStart | formatDateTime }}
      </template>
      <template v-slot:[`item.testEnd`]="{ item: { testEnd } }">
        {{ testEnd | formatDateTime }}
      </template>
      <template v-slot:[`item.action`]="{ item: { id } }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              {{ $vuetify.lang.t("$vuetify.option") }}
              <v-icon right dark> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="copyTest(id)" link>
              <v-list-item-title>{{
                $vuetify.lang.t("$vuetify.copyTest")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'Test.Edit', params: { id } }" link>
              <v-list-item-title>{{
                $vuetify.lang.t("$vuetify.edit")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="deletePrompt(id)" link>
              <v-list-item-title>{{
                $vuetify.lang.t("$vuetify.delete")
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </data-table>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline"> Delete selected data? </v-card-title>

        <v-card-text>
          This action is irreversible. Deleting this data means other data that
          containing this data will also be deleted.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error darken-1"
            @click="deleteData()"
            :loading="loadingDelete"
            text
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { format } from "date-fns";
import { mapActions } from "vuex";
import DataTable from "@/components/DataTable.js";
import Test from "@/services/test";

export default {
  name: "Test.Index",
  components: {
    DataTable
  },
  data() {
    return {
      totalData: 0,
      tests: [],
      loading: true,
      loadingDelete: false,
      options: {
        search: ""
      },
      headers: [
        { text: "Test Name", value: "name" },
        { text: "Duration (minutes)", value: "duration" },
        { text: "Calculation System", value: "calculationSystem" },
        { text: "Test Start", value: "testStart" },
        { text: "Test End", value: "testEnd" },
        { text: "Action", value: "action", sortable: false }
      ],
      dialog: false,
      selectedId: "",
      searchForm: null
    };
  },
  watch: {
    options: {
      handler() {
        this.getTests();
      },
      deep: true
    }
  },
  filters: {
    formatDateTime(dateTime) {
      return format(new Date(dateTime), "PPPP pp");
    },
    formatCalculationSystem(calculationSystem) {
      if (calculationSystem.length < 4) return calculationSystem.toUpperCase();

      return calculationSystem;
    }
  },
  created() {
    this.setNavbarTitle("Manage Test");
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async getTests() {
      this.loading = true;
      this.tests = [];
      const tests = await Test.index(this.options);
      this.tests = tests.data.data.tests;
      this.totalData = tests.data.data.total_data;
      this.loading = false;
    },
    async deletePrompt(id) {
      this.selectedId = id;
      this.dialog = true;
    },
    async deleteData() {
      try {
        this.loadingDelete = true;
        await Test.delete(this.selectedId);
        await this.getTests();
      } finally {
        this.dialog = false;
        this.loadingDelete = false;
      }
    },
    async copyTest(id) {
      await Test.copy(id);
      await this.getTests();
    },
    clearSearch() {
      this.options.search = undefined;
      this.searchForm = null;
    }
  }
};
</script>

<style scoped>
.capitalize {
  text-transform: capitalize;
}
</style>
