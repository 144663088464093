var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary","to":{ name: 'Test.Create' }}},[_vm._v("New Test")])],1),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h1',[_vm._v("Test")])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();_vm.options.search = _vm.searchForm}}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchForm ? 'mdi-close' : '',"prepend-icon":"mdi-magnify","label":_vm.$vuetify.lang.t('$vuetify.search'),"single-line":"","hide-details":""},on:{"click:append":_vm.clearSearch},model:{value:(_vm.searchForm),callback:function ($$v) {_vm.searchForm=$$v},expression:"searchForm"}})],1)],1)],1),_c('data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tests,"options":_vm.options,"server-items-length":_vm.totalData,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"items-per-page-text":"$vuetify.dataTable.itemsPerPageText","first-icon":"mdi-chevron-double-left","last-icon":"mdi-chevron-double-right","prev-icon":"mdi-chevron-left","next-icon":"mdi-chevron-right","items-per-page-options":[25, 50, 100, -1],"options":options,"pagination":pagination,"show-first-last-page":true,"show-current-page":true},on:{"update:options":updateOptions}})]}},{key:"item.calculationSystem",fn:function(ref){
var calculationSystem = ref.item.calculationSystem;
return [_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(_vm._f("formatCalculationSystem")(calculationSystem)))])]}},{key:"item.testStart",fn:function(ref){
var testStart = ref.item.testStart;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(testStart))+" ")]}},{key:"item.testEnd",fn:function(ref){
var testEnd = ref.item.testEnd;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(testEnd))+" ")]}},{key:"item.action",fn:function(ref){
var id = ref.item.id;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.option"))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.copyTest(id)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.copyTest")))])],1),_c('v-list-item',{attrs:{"to":{ name: 'Test.Edit', params: { id: id } },"link":""}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.edit")))])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.deletePrompt(id)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.delete")))])],1)],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Delete selected data? ")]),_c('v-card-text',[_vm._v(" This action is irreversible. Deleting this data means other data that containing this data will also be deleted. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error darken-1","loading":_vm.loadingDelete,"text":""},on:{"click":function($event){return _vm.deleteData()}}},[_vm._v(" Delete ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }